<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <b-card class="invoice-preview-card" >
          <!-- Header -->
          <b-card-body class="px-0" style="height: 47rem; display: flex;align-items: center;justify-content: center;">
            <b-row>
              <b-col cols="12" style="display: flex;justify-content: center;">
                <h2>คุณต้องการออกจากระบบ?</h2>
              </b-col>
              <b-col cols="12" style="display: flex;justify-content: center;">
                <div class="demo-inline-spacing">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  @click="logout"
                >
                  ยืนยัน
                </b-button>

              </div>
              </b-col>
            </b-row>
            
          </b-card-body>
        
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, onUnmounted, watch } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BTabs,
  BTab,
  BImg,
  BBadge,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import openbillStore from "./openbillStoreModule";
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BTabs,
    BTab,
    Logo,
    BImg,
    ToastificationContent,
    BBadge,
  },
  data() {
    return {
      status: "In Stock"
    }
  },
  setup() {
   

   
    return {  };
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      this.$router.push({ name: "auth-login" });
      // Redirect to login page
      // if (router.currentRoute.path.substring(0,5) == '/shop') {
      //   this.$router.push({ name: "shopauth-login" });
      // } else {
      //   this.$router.push({ name: "adminauth-login" });
      // }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
}
</style>
